
/**************START FIREBASE **************/
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBkXVJL8v35BnDoxbYfqtXLH3cid5thNNs",
  authDomain: "legaldraftai.firebaseapp.com",
  projectId: "legaldraftai",
  storageBucket: "legaldraftai.appspot.com",
  messagingSenderId: "516540918142",
  appId: "1:516540918142:web:dcd60801dfbaa4e0a92b2f",
  measurementId: "G-KHKENYQJLH"
};

/**************END FIREBASE **************/

let app: FirebaseApp | null = null;
let db: any = null;
const firebaseApp = (): FirebaseApp => {
    if (app) return app;

    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    const analytics = getAnalytics(app);

    return app;

}

const firebaseDB = (): Firestore => {
    if (db) return db;

    db = getFirestore(firebaseApp());
    return db;
}

export { firebaseApp, firebaseDB };
