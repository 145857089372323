import React from "react";

import Hero from "./Hero";
import Intro from "./Intro";
import Features from "./Features";
import Footer from "./Footer";

function Home() {
  return (
    <div style={{ paddingTop: 40 }}>
      <Hero />
      {/* <Intro/>
            <Features/> */}
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
