import React from "react";

import { Col, Row, Image, Container } from "react-bootstrap";
import DownloadButton from "../../common/DownloadButton";
import EmailForm from "../../common/EmailForm";

function Hero() {
  return (
    <Container style={{ marginBottom: 160, padding: 20 }}>
      <Row className={"justify-content-center"}>
        <Col
          sm={12}
          md={12}
          lg={5}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: 40,
          }}
        >
          <h1>Daily Outfit Planner</h1>
          <p style={{ marginTop: 20, marginBottom: 40 }}>
            Upload your wardrobe and let our app curate personalized, weather-based outfit
            suggestions for you. Make getting dressed the easiest part of your day!
          </p>

          <DownloadButton />
        </Col>

        <Col sm={12} md={6} lg={5} style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ width: "100%", height: "auto" }} src={"/images/mocks/1.png"} />
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
