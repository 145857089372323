import React from 'react';
import './styles/_root.scss';

import Home from "./screens/home/Home";

function App() {
  return (
      <div style={{
        // backgroundImage: `url("/images/blob.svg")`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: '150% 100%',
        // backgroundPositionY: -200,
        // backgroundPositionX: 100,
        // backgroundAttachment: 'fixed',
        // height: '100%'
      }}>
        <Home/>
      </div>
  );
}

export default App;
